import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import ReactFullpage, { FullpageApi } from '@fullpage/react-fullpage';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';

import {
  Dna,
  Home,
  HomeProps,
  Layout,
  MapProps,
  Team,
  Map,
  TeamProps,
  Testimonials,
  TestimonialsProps,
  DnasProps,
} from '../components/organisms';
import { MemberProps, MapContactProps, TestimonialProps, StatisticProps, DnaItemProps } from '../components/molecules';

const query = graphql`
  query NextPageQuery {
    strapiHomePage {
      pretitle
      title
      description
      video {
        url
        mime
      }
    }

    strapiDnaPage {
      pretitle
      title
    }

    strapiTeamPage {
      pretitle
      title
    }

    allStrapiDna(sort: { fields: order }) {
      edges {
        node {
          pretitle
          name
          description
          image {
            localFile {
              childImageSharp {
                fixed(width: 1024) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }

    allStrapiTeam(sort: { fields: order }) {
      edges {
        node {
          fullname
          function
          location
          linkedin
          order
          image {
            localFile {
              childImageSharp {
                fixed(width: 512) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          hoverImage {
            localFile {
              childImageSharp {
                fixed(width: 512) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }

    mapPeople: allStrapiTeam(filter: { region: { ne: null } }) {
      edges {
        node {
          id
          fullname
          contact
          region
          image {
            localFile {
              childImageSharp {
                fixed(width: 512) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }

    strapiTestimonialPage {
      pretitle
      title
    }

    allStrapiTestimonial(sort: { fields: order }) {
      edges {
        node {
          title
          extract
          content
          function
          order
          workPlace
          firstname
          lastname
        }
      }
    }

    allStrapiStatistic(sort: { fields: order }) {
      edges {
        node {
          id
          title
          value
          icon {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    strapiMapPage {
      pretitle
      title
      description
    }
  }
`;

type Edge<T> = {
  edges: [
    {
      node: T;
    },
  ];
};

type QueryProps = {
  strapiHomePage: HomeProps;
  strapiDnaPage: DnasProps;
  strapiTeamPage: TeamProps;
  strapiTestimonialPage: TestimonialsProps;
  strapiMapPage: MapProps;
  allStrapiDna: Edge<DnaItemProps>;
  allStrapiTeam: Edge<MemberProps>;
  allStrapiTestimonial: Edge<TestimonialProps>;
  mapPeople: Edge<MapContactProps>;
  allStrapiStatistic: Edge<StatisticProps>;
};

const Slide = styled.div`
  height: 100%;
`;

type FullpageValue = {
  fullpageApi: FullpageApi;
};

const FullpageContext = createContext<FullpageValue | undefined>(undefined);

export function useFullpage() {
  const value = useContext(FullpageContext);

  if (!value) {
    throw new Error();
  }

  return value;
}

export default function Next() {
  const [fullpageRendered, setFullpageRendered] = useState(false);

  return (
    <StaticQuery
      query={query}
      render={(data: QueryProps) => (
        <Layout>
          <ReactFullpage
            navigation
            licenseKey={process.env.FULLPAGE_LICENSE}
            paddingTop="72px"
            scrollingSpeed={1000}
            scrollOverflow
            scrollOverflowOptions={{ preventDefault: false }}
            normalScrollElements=".fullpage-no-scroll"
            afterRender={() => {
              setFullpageRendered(true);
            }}
            render={({ state, fullpageApi }) => {
              if (state.initialized && fullpageRendered) {
                setTimeout(() => {
                  fullpageApi.reBuild();
                }, 100);
                setFullpageRendered(false);
              }
              return (
                <FullpageContext.Provider
                  value={{
                    fullpageApi,
                  }}
                >
                  <div id="fullpage-wrapper">
                    <section className="section full-section">
                      <Slide>
                        <Home {...data.strapiHomePage} />
                      </Slide>
                    </section>
                    <section className="section">
                      <Slide>
                        <Dna {...data.strapiDnaPage} values={data.allStrapiDna.edges.map((e) => e.node)} />
                      </Slide>
                    </section>
                    <section className="section team-page">
                      <Slide>
                        <Team {...data.strapiTeamPage} members={data.allStrapiTeam.edges.map((e) => e.node)} />
                      </Slide>
                    </section>
                    <section className="section testimonial-page">
                      <Slide>
                        <Testimonials
                          {...data.strapiTestimonialPage}
                          testimonials={data.allStrapiTestimonial.edges.map((e) => e.node)}
                        />
                      </Slide>
                    </section>
                    <section className="section map-page">
                      <Map
                        {...data.strapiMapPage}
                        people={data.mapPeople.edges.map((e) => e.node)}
                        statistics={data.allStrapiStatistic.edges.map((e) => e.node)}
                      />
                    </section>
                  </div>
                </FullpageContext.Provider>
              );
            }}
          />
        </Layout>
      )}
    />
  );
}
